<template>
  <vx-card no-shadow class="mb-12">
    <!-- Img Row -->
    <div class="flex flex-wrap items-center mb-base">
      <vs-avatar v-if="ganti == false" :src="getImage('/profil/'+user.avatar)" size="70px" class="mr-4 mb-4" />
      <vs-avatar v-else :src="getImage('/profil/'+gantiGambar)" size="70px" class="mr-4 mb-4" />
      <div>
        <vs-button class="mr-4 sm:mb-0 mb-2" @click="toggleShow">Upload photo</vs-button>
        <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
      </div>
    </div>

    <my-upload field="avatar"
      @crop-success="cropSuccess"
      @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail"
      v-model="show"
      :width="300"
      :height="300"
      :url="urlUpload"
      langType="en"
      img-format="png"
      :params="params"
      :headers="headers">
    </my-upload>

    <!-- 
      :params="params"
      :headers="headers" 
    Info -->
    <vs-input data-vv-validate-on="blur" v-validate="'required'" class="w-full" :disabled="true" label-placeholder="Username" v-model="dataUser.username" name="username"></vs-input>
    <span class="text-danger text-sm">{{ errors.first('username') }}</span>

    <vs-input data-vv-validate-on="blur" v-validate="'required'" class="w-full" label-placeholder="Nama" v-model="dataUser.nama" name="nama"></vs-input>
    <span class="text-danger text-sm">{{ errors.first('nama') }}</span>

    <vs-input data-vv-validate-on="blur" v-validate="'required|email'" class="w-full" label-placeholder="Email" v-model="dataUser.email" name="email"></vs-input>
    <span class="text-danger text-sm">{{ errors.first('email') }}</span>

    <vs-input data-vv-validate-on="blur" v-validate="'required|numeric'" class="w-full" label-placeholder="Telepon" v-model="dataUser.telepon" name="telepon"></vs-input>
    <span class="text-danger text-sm">{{ errors.first('telepon') }}</span>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-between">
      <vs-button class="mt-2" :disabled="!validateForm" @click="openConfirm">Simpan Perubahan</vs-button>
      <vs-button class="mt-2" type="border" color="warning" @click="reset">Reset</vs-button>
    </div>
  </vx-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import 'babel-polyfill'
import myUpload from 'vue-image-crop-upload'
export default {
  data () {
    return {
      dataUser: [],
      show: false,
      ganti: false,
      gantiGambar: '',
      urlUpload: process.env.VUE_APP_BACKEND_URL+'/imagechange'
    }
  },
  computed: {
    ...mapGetters({
      user : 'auth/user',
    }),
    validateForm () {
      return !this.errors.any() && this.user.username !== '' && this.user.nama !== '' && this.user.email !== '' && this.user.telepon !== ''
    },
    params(){
      return {
          token: this.user.api_token,
          name: 'avatar'

      }
    },
    headers(){
      return {
        'Authorization': 'Bearer ' + this.user.api_token,
      }
    }
  },        
  components: {
    'my-upload': myUpload
  },
  mounted(){
    let config = {
      headers: {
          'Authorization': 'Bearer ' + this.user.api_token,
      },
    }
    this.axios.get('/setting',config)
    .then((response) => {
      let {data } = response.data
      this.dataUser = data
    })
    .catch((error) => {
      let {data} = error.response
      this.$vs.notify({
        title: 'Error',
        text  : data.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  },
  methods: {
    ...mapActions({
      setAuth   : 'auth/set',
    }),
    openConfirm() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Yakin ingin mengubah akun?`,
        text: 'Anda akan otomatis logout!',
        accept: this.save
      })
    },
    save(){
      this.$vs.loading()
      if (!this.errors.any()) {
        let formData = new FormData()
        formData.set('username', this.dataUser.username)
        formData.set('nama', this.dataUser.nama)
        formData.set('telepon', this.dataUser.telepon)
        formData.set('email', this.dataUser.email)
        
        let config = {
          headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }

        this.axios.post('/update', formData, config)
        .then((response) => {
          this.$vs.loading.close()
          let {data} = response
          this.$vs.notify({
            title: 'Berhasil',
            color  : 'success',
            text  : data.message,
          })
          this.setAuth({})
          this.$router.push('/login')
        })
        .catch((error) => {
          let {data} = error.response
          this.$vs.notify({
            title: data.status,
            text  : 'Ada kesalahan coba lagi',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text  : 'Cek pengisian form',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        this.$vs.loading.close()
      }
    }, 
    reset(){
      this.$vs.loading()
      let config = {
        headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
        },
      }
      this.axios.get('/setting',config)
        .then((response) => {
          let {data } = response.data
          this.dataUser = data
          this.$vs.loading.close()
        })
        .catch((error) => {
          let {data} = error.response
          this.$vs.notify({
            title: 'Error',
            text  : data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    // img-upload
    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(avatar, field){
      this.$vs.notify({
        text: 'Has Cropped',
        iconPack: field,
        color: 'success'
      })
    },
    cropUploadSuccess(jsonData, field){
      this.gantiGambar = jsonData.avatar;
      this.ganti = true;
      this.$vs.notify({
        text: 'Berhasil ganti foto, Anda akan logout otomatis',
        iconPack: field,
        color: 'success'
      })
      setTimeout(()=>{
        this.setAuth({})
        this.$router.push('/login')
      },4000);
    },
    cropUploadFail(status, field){
      this.$vs.notify({
        title: 'Error',
        text: 'Terjadi kesalahan, coba beberapa saat lagi',
        iconPack: field,
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    }
  }
}
</script>

<style lang="scss">
  .vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right {
    display: none;
  }
  .vue-image-crop-upload .vicp-wrap {
    width: 100%;
  }
</style>
